.schema-title {
  display: block;
  background-color: #eee;
  color: #333;
  padding: 10px;
  font-weight: 400;
  font-size: 16px;
}

.form-input-area {
  padding-top: 15px;
  padding-right: 15px;
}

.checkbox-style {
  padding-left: 20px;
}

.label-text {
  margin-bottom: 5px;
  font-weight: 500;
  margin-left: 20px;
  width: 100% !important;
}

.required-field {
  color: red;
}
